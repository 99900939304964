export const processItem = [
  { ID: 100, DESCRIPTION: 'Cell Assesbly' },
  { ID: 400, DESCRIPTION: 'Top Plate' }
];

export const controlText = [
  { id: 1, name: 'Process' },
  { id: 2, name: 'Machine' }
];

export const machinesItems = [
  { id: 1, name: 'Top Plate Inspection 1' },
  { id: 2, name: 'Top Plate Inspection 2' }
];

/*
export const machinesNames = [
    { PROCESS_ID:100,ID: 1,DESCRIPTION: 'Winding Machine #6'},
    { PROCESS_ID:100,ID: 2,DESCRIPTION: 'Winding Machine #7' },
    { PROCESS_ID:100,ID: 3,DESCRIPTION: 'Winding Machine #8' },
    { PROCESS_ID:100,ID: 4,DESCRIPTION: 'Wound Electrode Pushing 2' },
    { PROCESS_ID:100,ID: 5,DESCRIPTION: 'Separator Shrinkage 2' },
    { PROCESS_ID:100,ID: 6,DESCRIPTION: 'Bottom Insulating Disk Insertion 2' },
    { PROCESS_ID:100,ID: 7,DESCRIPTION: 'Wound Electrode insertion 2' },
    { PROCESS_ID:100,ID: 8,DESCRIPTION: 'Anode Lead Welding 2' },
    { PROCESS_ID:100,ID: 9,DESCRIPTION: 'Upper Insulating Disk Insertion/Grooving 2' },
    { PROCESS_ID:100,ID: 10,DESCRIPTION: 'Sealant Coating 2' },
    { PROCESS_ID:100,ID: 11,DESCRIPTION: 'Top Plate Welding 2' },
    { PROCESS_ID:100,ID: 12,DESCRIPTION: 'Electrolyte Pouring 2' },
    { PROCESS_ID:100,ID: 13,DESCRIPTION: 'Top Plate Insertion 2' },
    { PROCESS_ID:100,ID: 14,DESCRIPTION: 'Crimping 2' },
    { PROCESS_ID:100,ID: 15,DESCRIPTION: 'Cell Washing 2' },
    { PROCESS_ID:400,ID: 16,DESCRIPTION: 'Top Plate Inspection 1'},
    { PROCESS_ID:400,ID: 27,DESCRIPTION: 'Top Plate Inspection 2' }
];
*/

export const machinesNames = [
  { "PROCESS_ID": 100, "LINE_NUMBER": 2, "ID": 2001, "DESCRIPTION": "Winding Machine #6", "PRODUCTION_SPEED": 70, "TARGET_EFFICIENCY": 74 },
  { "PROCESS_ID": 100, "LINE_NUMBER": 2, "ID": 2002, "DESCRIPTION": "Winding Machine #7", "PRODUCTION_SPEED": 70, "TARGET_EFFICIENCY": 74 },
  { "PROCESS_ID": 100, "LINE_NUMBER": 2, "ID": 2003, "DESCRIPTION": "Winding Machine #8", "PRODUCTION_SPEED": 70, "TARGET_EFFICIENCY": 74 },
  { "PROCESS_ID": 100, "LINE_NUMBER": 2, "ID": 2101, "DESCRIPTION": "Wound Electrode Pushing 2", "PRODUCTION_SPEED": 120, "TARGET_EFFICIENCY": 74 },
  { "PROCESS_ID": 100, "LINE_NUMBER": 2, "ID": 2102, "DESCRIPTION": "Separator Shrinkage 2", "PRODUCTION_SPEED": 120, "TARGET_EFFICIENCY": 74 },
  { "PROCESS_ID": 100, "LINE_NUMBER": 2, "ID": 2103, "DESCRIPTION": "Bottom Insulating Disk Insertion 2", "PRODUCTION_SPEED": 120, "TARGET_EFFICIENCY": 74 },
  { "PROCESS_ID": 100, "LINE_NUMBER": 2, "ID": 2201, "DESCRIPTION": "Wound Electrode Insertion 2", "PRODUCTION_SPEED": 120, "TARGET_EFFICIENCY": 74 },
  { "PROCESS_ID": 100, "LINE_NUMBER": 2, "ID": 2202, "DESCRIPTION": "Anode Lead Welding 2", "PRODUCTION_SPEED": 120, "TARGET_EFFICIENCY": 74 },
  { "PROCESS_ID": 100, "LINE_NUMBER": 2, "ID": 2203, "DESCRIPTION": "Upper Insulating Ring Insertion / Grooving 2", "PRODUCTION_SPEED": 120, "TARGET_EFFICIENCY": 74 },
  { "PROCESS_ID": 100, "LINE_NUMBER": 2, "ID": 2204, "DESCRIPTION": "Sealant Coating 2", "PRODUCTION_SPEED": 120, "TARGET_EFFICIENCY": 74 },
  { "PROCESS_ID": 100, "LINE_NUMBER": 2, "ID": 2205, "DESCRIPTION": "Top Plate Welding 2", "PRODUCTION_SPEED": 120, "TARGET_EFFICIENCY": 74 },
  { "PROCESS_ID": 100, "LINE_NUMBER": 2, "ID": 2206, "DESCRIPTION": "Electrolyte Pouring 2", "PRODUCTION_SPEED": 120, "TARGET_EFFICIENCY": 74 },
  { "PROCESS_ID": 100, "LINE_NUMBER": 2, "ID": 2207, "DESCRIPTION": "Top Plate Insertion 2", "PRODUCTION_SPEED": 120, "TARGET_EFFICIENCY": 74 },
  { "PROCESS_ID": 100, "LINE_NUMBER": 2, "ID": 2208, "DESCRIPTION": "Crimping 2", "PRODUCTION_SPEED": 120, "TARGET_EFFICIENCY": 74 },
  { "PROCESS_ID": 100, "LINE_NUMBER": 2, "ID": 2301, "DESCRIPTION": "Cell Washing 2", "PRODUCTION_SPEED": 120, "TARGET_EFFICIENCY": 74 },
  { "PROCESS_ID": 100, "LINE_NUMBER": 2, "ID": 2302, "DESCRIPTION": "Weight Checker 2", "PRODUCTION_SPEED": 120, "TARGET_EFFICIENCY": 74 },
  { "PROCESS_ID": 100, "LINE_NUMBER": 2, "ID": 2303, "DESCRIPTION": "Drop Test 2", "PRODUCTION_SPEED": 120, "TARGET_EFFICIENCY": 74 },
  { "PROCESS_ID": 100, "LINE_NUMBER": 2, "ID": 2304, "DESCRIPTION": "Downsizing VA Checker 2", "PRODUCTION_SPEED": 120, "TARGET_EFFICIENCY": 74 },
  { "PROCESS_ID": 100, "LINE_NUMBER": 2, "ID": 2305, "DESCRIPTION": "Short Circuit Current Checker 2", "PRODUCTION_SPEED": 120, "TARGET_EFFICIENCY": 74 },
  { "PROCESS_ID": 100, "LINE_NUMBER": 2, "ID": 2306, "DESCRIPTION": "Pre-Discharge 2", "PRODUCTION_SPEED": 120, "TARGET_EFFICIENCY": 74 },
  { "PROCESS_ID": 100, "LINE_NUMBER": 2, "ID": 2401, "DESCRIPTION": "Wiping / Fall / OCV 2", "PRODUCTION_SPEED": 120, "TARGET_EFFICIENCY": 74 },
  { "PROCESS_ID": 100, "LINE_NUMBER": 2, "ID": 2402, "DESCRIPTION": "Coating 2", "PRODUCTION_SPEED": 120, "TARGET_EFFICIENCY": 74 },
  { "PROCESS_ID": 100, "LINE_NUMBER": 2, "ID": 2403, "DESCRIPTION": "Cell Boxing 2", "PRODUCTION_SPEED": 120, "TARGET_EFFICIENCY": 74 },
  { "PROCESS_ID": 400, "LINE_NUMBER": 2, "ID": 2309, "DESCRIPTION": "Top Plate Inspection 1", "PRODUCTION_SPEED": 120, "TARGET_EFFICIENCY": 74 },
  { "PROCESS_ID": 400, "LINE_NUMBER": 2, "ID": 4609, "DESCRIPTION": "Top Plate Inspection 2", "PRODUCTION_SPEED": 120, "TARGET_EFFICIENCY": 74 },
  { "PROCESS_ID": 400, "LINE_NUMBER": 2, "ID": 3402, "DESCRIPTION": "Top Plate Inspection 3", "PRODUCTION_SPEED": 120, "TARGET_EFFICIENCY": 74 },
  { "PROCESS_ID": 400, "LINE_NUMBER": 2, "ID": 3403, "DESCRIPTION": "Top Plate Inspection 4", "PRODUCTION_SPEED": 120, "TARGET_EFFICIENCY": 74 }
]


export const machinesEffOpTimes = [
  {
    H_INTERVAL: '2024-01-08T00:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-08T01:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-08T02:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-08T03:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-08T04:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-08T05:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-08T06:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 4,
    OP_RATE: 6.67
  },
  {
    H_INTERVAL: '2024-01-08T07:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 16,
    OP_RATE: 26.67
  },
  {
    H_INTERVAL: '2024-01-08T08:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 19,
    OP_RATE: 31.67
  },
  {
    H_INTERVAL: '2024-01-08T09:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 34,
    OP_RATE: 56.67
  },
  {
    H_INTERVAL: '2024-01-08T10:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 21,
    OP_RATE: 35
  },
  {
    H_INTERVAL: '2024-01-08T11:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-08T12:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 31,
    OP_RATE: 51.67
  },
  {
    H_INTERVAL: '2024-01-08T13:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 26,
    OP_RATE: 43.33
  },
  {
    H_INTERVAL: '2024-01-08T14:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 39,
    OP_RATE: 65
  },
  {
    H_INTERVAL: '2024-01-08T15:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 11,
    OP_RATE: 18.33
  },
  {
    H_INTERVAL: '2024-01-08T16:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 5,
    OP_RATE: 8.33
  },
  {
    H_INTERVAL: '2024-01-08T17:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 9,
    OP_RATE: 15
  },
  {
    H_INTERVAL: '2024-01-08T18:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 41,
    OP_RATE: 68.33
  },
  {
    H_INTERVAL: '2024-01-08T19:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 40,
    OP_RATE: 66.67
  },
  {
    H_INTERVAL: '2024-01-08T20:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 40,
    OP_RATE: 66.67
  },
  {
    H_INTERVAL: '2024-01-08T21:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 50,
    OP_RATE: 83.33
  },
  {
    H_INTERVAL: '2024-01-08T22:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 42,
    OP_RATE: 70
  },
  {
    H_INTERVAL: '2024-01-08T23:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 36,
    OP_RATE: 60
  },
  {
    H_INTERVAL: '2024-01-09T00:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 38,
    OP_RATE: 63.33
  },
  {
    H_INTERVAL: '2024-01-09T01:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 21,
    OP_RATE: 35
  },
  {
    H_INTERVAL: '2024-01-09T02:00:00.000Z',
    MEASUREMENT_TIME: 47,
    ON_TIME: 15,
    OP_RATE: 31.91
  },
  {
    H_INTERVAL: '2024-01-09T03:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-09T04:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-09T05:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-09T06:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-09T07:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-09T08:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-09T09:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-09T10:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-09T11:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-09T12:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-09T13:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-09T14:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-09T15:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-09T16:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-09T17:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-09T18:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-09T19:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-09T20:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-09T21:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-09T22:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-09T23:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-10T00:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-10T01:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-10T02:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-10T03:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-10T04:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-10T05:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-10T06:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  },
  {
    H_INTERVAL: '2024-01-10T07:00:00.000Z',
    MEASUREMENT_TIME: 19,
    ON_TIME: 6,
    OP_RATE: 31.58
  },
  {
    H_INTERVAL: '2024-01-10T08:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 3,
    OP_RATE: 5
  },
  {
    H_INTERVAL: '2024-01-10T09:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 30,
    OP_RATE: 50
  },
  {
    H_INTERVAL: '2024-01-10T10:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 37,
    OP_RATE: 61.67
  },
  {
    H_INTERVAL: '2024-01-10T11:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 38,
    OP_RATE: 63.33
  },
  {
    H_INTERVAL: '2024-01-10T12:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 41,
    OP_RATE: 68.33
  },
  {
    H_INTERVAL: '2024-01-10T13:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 40,
    OP_RATE: 66.67
  },
  {
    H_INTERVAL: '2024-01-10T14:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 36,
    OP_RATE: 60
  },
  {
    H_INTERVAL: '2024-01-10T15:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 40,
    OP_RATE: 66.67
  },
  {
    H_INTERVAL: '2024-01-10T16:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 33,
    OP_RATE: 55
  },
  {
    H_INTERVAL: '2024-01-10T17:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 23,
    OP_RATE: 38.33
  },
  {
    H_INTERVAL: '2024-01-10T18:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 23,
    OP_RATE: 38.33
  },
  {
    H_INTERVAL: '2024-01-10T19:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 38,
    OP_RATE: 63.33
  },
  {
    H_INTERVAL: '2024-01-10T20:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 31,
    OP_RATE: 51.67
  },
  {
    H_INTERVAL: '2024-01-10T21:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 35,
    OP_RATE: 58.33
  },
  {
    H_INTERVAL: '2024-01-10T22:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 37,
    OP_RATE: 61.67
  },
  {
    H_INTERVAL: '2024-01-10T23:00:00.000Z',
    MEASUREMENT_TIME: 60,
    ON_TIME: 28,
    OP_RATE: 46.67
  },
  {
    H_INTERVAL: '2024-01-11T00:00:00.000Z',
    MEASUREMENT_TIME: 0,
    ON_TIME: 0,
    OP_RATE: 0
  }
]