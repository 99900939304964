import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../scss/sidemenu.scss';
import { Button } from 'primereact/button';
import { PanelMenu } from 'primereact/panelmenu';


function SideMenu() {
    const [visible, setVisible] = useState(false);
    const toggleMenu = () => {
        setVisible(!visible);
    };
    const itemRenderer = (item) => (
        <div className='p-menuitem-content'>
            <Link to={item.link} className="flex align-items-center p-menuitem-link" style={{ textDecoration: 'none' }}>
                <span className={item.icon} />
                <span className="mx-0" style={{ textDecoration: "none" }}>{item.label}</span>
            </Link>
        </div>
    );
    let items = [
        {
            template: () => {
                return (
                    <span className="inline-flex align-items-center gap-1 px-1 py-1">

                        <span className="font-medium text-xl font-semibold">
                            PECA<span className="text-primary">PDCS</span>
                        </span>
                    </span>
                );
            }
        },
        /*    {
                separator: true
            }, */
        {
            label: 'Charts',
            items: [
                {
                    label: 'Efficiency - Operation Time',
                    link: '/chart',
                    // icon: 'pi pi-chart',
                    //  shortcut: '⌘+O',
                    template: itemRenderer
                },
                {
                    label: 'Efficiency - Units Produced',
                    link: '#',
                    //  icon: 'pi pi-chart',
                    // shortcut: '⌘+U',
                    template: itemRenderer
                },
                {
                    label: 'Machine Stops',
                    link: '#',
                    //  icon: 'pi pi-chart',
                    //  shortcut: '⌘+S',
                    template: itemRenderer
                },
                {
                    label: 'CA | VA Checker Running Totals',
                    link: '#',
                    //  icon: 'pi pi-chart',
                    //   shortcut: '⌘+C',
                    template: itemRenderer
                },
                {
                    label: 'TPA | Inspection NG',
                    link: '#',
                    //   icon: 'pi pi-chart',
                    //  shortcut: '⌘+I',
                    template: itemRenderer
                },
                {
                    label: 'Line',
                    link: '#',
                    //   icon: 'pi pi-chart',
                    //  shortcut: '⌘+L',
                    template: itemRenderer
                },
                {
                    label: 'Pareto | Machine Stops',
                    link: '#',
                    //     icon: 'pi pi-chart',
                    //   shortcut: '⌘+S',
                    template: itemRenderer
                },
                {
                    label: 'Pareto | Reject Ratio',
                    link: '#',
                    //    icon: 'pi pi-chart',
                    //    shortcut: '⌘+S',
                    template: itemRenderer
                },
                {
                    label: 'Wait Times',
                    link: '#',
                    // icon: 'pi pi-chart',
                    //  shortcut: '⌘+S',
                    template: itemRenderer
                },
                {
                    label: 'Weight Checker',
                    link: '#',
                    //   icon: 'pi pi-chart',
                    //   shortcut: '⌘+S',
                    template: itemRenderer
                },
            ]
        },
        {
            label: 'Dashboards',
            items: [
                {
                    label: 'Crud Page',
                    link: '/crud',
                    //   icon: 'pi pi-cog',
                    //    shortcut: '⌘+O',
                    template: itemRenderer
                },
                {
                    label: 'Cell Assembly',
                    link: '#',
                    //   icon: 'pi pi-cog',
                    //    shortcut: '⌘+O',
                    template: itemRenderer
                },
                {
                    label: 'Top Plate Assembly',
                    link: '#',
                    //  icon: 'pi pi-inbox',
                    //  shortcut: '⌘+M',
                    template: itemRenderer
                }
            ]
        },
        {
            label: 'Operator Entry',
            items: [
                {
                    label: 'Top Plate Assembly',
                    link: '#',
                    //  icon: 'pi pi-cog',
                    // shortcut: '⌘+O',
                    template: itemRenderer
                }
            ]
        },
        /* {
             label: 'Profile',
             items: [
                 {
                     label: 'Options',
                     link:'/options',
                     icon: 'pi pi-cog',
                     shortcut: '⌘+O',
                     template: itemRenderer
                 },
                 {
                     label: 'Messages',
                     link:'/Messages',
                     icon: 'pi pi-inbox',
                     shortcut: '⌘+M',
                     template: itemRenderer
                 },
                 {
                     label: 'Logout',
                     link:'#',
                     icon: 'pi pi-sign-out',
                     shortcut: '⌘+Q',
                     template: itemRenderer
                 }
             ]
         },
         {
             separator: true
         } */
    ];

    return (
        <div>
            <Button icon="pi pi-angle-right" onClick={toggleMenu} className="toggleRightButton" />
            <nav className={visible ? 'nav-menu active' : 'nav-menu'}>

                <ul className='nav-menu-items' onClick={toggleMenu}>
                    <li className='navbar-toggle'>

                        <Button icon="pi pi-angle-left" onClick={toggleMenu} className="toggleLeftButton" />

                    </li>

                    {visible && (
                        <PanelMenu model={items} className="togglePanel" style={{ textDecoration: "none", background: "none" }} />
                    )}
                </ul>
            </nav>
        </div>
    )
}

export default SideMenu