import React from 'react';

function PecaTop() {
  return (
    <div>
      <header class="d-print-none">
        <section id="panaHeader">
          <a id="panaHeaderPIW" href="https://iweb.is.jp.panasonic.com/gp/piw/en/" title="PIW Global Portal">
            PIW Global Portal
          </a>
          <div id="panaHeaderTxt">
            Panasonic Energy Corporation of America
            <div id="panaHeaderTxtCal">
              <span id="panaHeaderTxtCalDateTime">&nbsp;</span>
              <span id="panaHeaderTxtCalDayYear">&nbsp;</span>
            </div>
          </div>
          <div id="panaHeaderRight">
            <a id="panaHeaderRightPIW" href="https://iweb.is.jp.panasonic.com/scube/energy/en/" target="_blank" title="PIW Global Portal">
              PIW Global Portal
            </a>
            <span id="panaHeaderRightLogo" title="Panasonic Intranet">
              Panasonic Intranet
            </span>
          </div>
        </section>
      </header>
    </div>
  )
}

export default PecaTop