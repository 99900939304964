import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../scss/list.scss';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { machinesNames } from '../services/Controls';
import { getDataApi, saveApi } from '../services/appApi';
import { Calendar } from 'primereact/calendar';

function CrudPage({ title }) {

    let user_id=1;
    let emptyMachine = {
        machine_id:null,
        machine_name: '',
        machine_description: '',
        thereotical_capacity:null,
        target_efficiency: null,
        production_speed:null,
        start_Date: '',
        end_Date: '',
        wait_time_sort_order: '',
        prod_count_flag: '',
        machine_code: '',
        user_id:user_id
    };

    const [machines, setMachines] = useState(null);
    const [machineDialog, setMachineDialog] = useState(false);
    const [deleteMachineDialog, setDeleteMachineDialog] = useState(false);
    const [deleteMachinesDialog, setDeleteMachinesDialog] = useState(false);
    const [machine, setMachine] = useState(emptyMachine);
    const [selectedMachines, setSelectedMachines] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
       
        const fetchCategories = async () => {
            try {
              
                 await getDataApi("machinePageLoad").then((response)=>{
                   
                       if(response.data.length>0){
                        let successFlag = response.data[0];
                        if (successFlag === 'Y') {
                            setMachines(response.data[1]);
                          } else {
                            setMachines(null);
                          }
                         //setSearch(response.data);
                       
                       }else{
                        setMachines(null);
                        }	
                       })
                //  let res = await axios.get(baseUrl + title, { params: { erp_id } });
                // setData(res.data);
            } catch (err) {
                console.log(err);
            }
        }
        fetchCategories();
    }, []);

    const openNew = () => {
        setMachine(emptyMachine);
        setSubmitted(false);
        setMachineDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setMachineDialog(false);
    }

    const hideDeleteMachineDialog = () => {
        setDeleteMachineDialog(false);
    }

    const hideDeleteMachinesDialog = () => {
        setDeleteMachinesDialog(false);
    }

    const saveMachine = () => {
        setSubmitted(true);
        if (machine.machine_name.trim()) {
          console.log(machine.machine_id);
          let _machines = [...machines];
            let _machine = { ...machine };
            if (machine.machine_id) {
                saveApi(machine,"machinePageUpdate").then((result) => {
                    if (result) {
                        let successFlag = result.data[0];
                        if (successFlag === 'Y') {
                            const index = findIndexById(machine.machine_id);
                            _machines[index] = _machine;
                            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Machine Updated', life: 3000 });
                        }else{
                            let err_msg=result.data[1];
                            toast.current.show({ severity: 'unsuccess', summary: err_msg, detail: 'Machine Not Created', life: 3000 });
                        }
                     // toast(result.data.message, toastOptions);
                    } else {
                        toast.current.show({ severity: 'unsuccess', summary: 'Un Successful', detail: 'Machine Not Updated', life: 3000 });
                    }
                  });
            }else {
                saveApi(machine,"machinePageInsert").then((result) => {
                    if (result) {
                        let successFlag = result.data[0];
                        if (successFlag === 'Y') {
                            _machine.machine_id = result.data[1];
                            _machines.push(_machine);
                            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Machine Created', life: 3000 });
                        }else{
                            let err_msg=result.data[1];
                            toast.current.show({ severity: 'unsuccess', summary: err_msg, detail: 'Machine Not Created', life: 3000 });
                        }
                     // toast(result.data.message, toastOptions);
                    } else {
                        toast.current.show({ severity: 'unsuccess', summary: 'Un Successful', detail: 'Machine Not Created', life: 3000 });
                    }
                  });  
            }
      

            setMachines(_machines);
            setMachineDialog(false);
            setMachine(emptyMachine);
        }
    }

    const editMachine = (machine) => {
        console.log(machine);
        setMachine({ ...machine });
       
        setMachineDialog(true);
    }

    const confirmDeleteMachine = (machine) => {
        setMachine(machine);
        setDeleteMachineDialog(true);
    }

    const deleteMachine = () => {
        if (machine.machine_id) {
        saveApi(machine,"machinePageDelete").then((result) => {
            if (result) {
                let successFlag = result.data[0];
                if (successFlag === 'Y') {
                    let _machines = machines.filter(val => val.machine_id !== machine.machine_id);
                    setMachines(_machines);
                    setDeleteMachineDialog(false);
                    setMachine(emptyMachine);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Machine Deleted', life: 3000 });
                }else{
                    let err_msg=result.data[1];
                    toast.current.show({ severity: 'unsuccess', summary: err_msg, detail: 'Machine Not Deleted', life: 3000 });
                }
             // toast(result.data.message, toastOptions);
            } else {
                toast.current.show({ severity: 'unsuccess', summary: 'Un Successful', detail: 'Machine Not Deleted', life: 3000 });
            }
          });
        }
        
    }

    const findIndexById = (ID) => {
        let index = -1;
        for (let i = 0; i < machines.length; i++) {
            if (machines[i].machine_id === ID) {
                index = i;
                break;
            }
        }

        return index;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteMachinesDialog(true);
    }

    const deleteSelectedMachines = () => {
        let _machines = machines.filter(val => !selectedMachines.includes(val));
        setMachines(_machines);
        setDeleteMachinesDialog(false);
        setSelectedMachines(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Machines Deleted', life: 3000 });
    }

    const onInputChange = (e, _name) => {
        console.log('machine_name '+_name);
        const val = (e.target && e.target.value) || '';
        console.log('val '+val);
        let _machine = { ...machine };
        _machine[`${_name}`] = val;
        console.log('mavhine_ '+_machine);
        setMachine(_machine);
        console.log(machine);
    }

    
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editMachine(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDeleteMachine(rowData)} />
            </React.Fragment>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:align-items-center justify-content-between">
            <span className="p-input-icon-right w-full md:w-auto">

                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." className="w-full lg:w-auto" />
            </span>
            <div className="mt-3 md:mt-0 flex justify-content-end">
                <Button icon="pi pi-plus" className="mr-2 p-button-rounded" onClick={openNew} tooltip="New" tooltipOptions={{ position: 'bottom' }} />
                <Button icon="pi pi-trash" className="p-button-danger mr-2 p-button-rounded" onClick={confirmDeleteSelected} disabled={!selectedMachines || !selectedMachines.length} tooltip="Delete" tooltipOptions={{ position: 'bottom' }} />
                <Button icon="pi pi-upload" className="p-button-help p-button-rounded" onClick={exportCSV} tooltip="Export" tooltipOptions={{ position: 'bottom' }} />
            </div>
        </div>
    );
    const machineDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" onClick={saveMachine} />
        </React.Fragment>
    );

    const deleteMachineDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteMachineDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteMachine} />
        </React.Fragment>
    );

    const deleteMachinesDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteMachinesDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedMachines} />
        </React.Fragment>
    );

    return (
        <div className='list'>
            <div className='listContainer'>
                <div className="datatable-crud-demo p-4">
                    <Toast ref={toast} />

                    <div className="text-3xl text-800 font-bold mb-4">PDCS Machine Names</div>
                   
                    <DataTable className='dataTable' ref={dt} value={machines} selection={selectedMachines} onSelectionChange={(e) => setSelectedMachines(e.value)}
                        dataKey="machine_id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} machines"
                        globalFilter={globalFilter} header={header} responsiveLayout="scroll">
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} exportable={false}></Column>
                        <Column field="machine_id" header="Id" sortable style={{ minWidth: '1rem' }}></Column>
                        <Column field="machine_name" header="Machine Name" sortable style={{ minWidth: '1rem' }}></Column>
                        <Column field="description" header="Description" sortable style={{ minWidth: '6rem' }}></Column>
                        <Column field="thereotical_capacity" header="Thereotical Capacity" sortable style={{ minWidth: '1rem' }}></Column>
                        <Column field="target_efficiency" header="Target Efficiency" sortable style={{ minWidth: '1rem' }}></Column>
                        <Column field="production_speed" header="Production Speed" sortable style={{ minWidth: '1rem' }}></Column>
                        <Column field="prod_enabled_flag" header="flag Capacity" sortable style={{ minWidth: '1rem' }}></Column>
                        <Column field="start_date" header="Start Date" sortable style={{ minWidth: '3rem' }}></Column>
                        <Column field="end_date" header="End Date" sortable style={{ minWidth: '3rem' }}></Column>
                        <Column field="wait_time_sort_order" header="Wait Time Sort Order" sortable style={{ minWidth: '1rem' }}></Column>
                        <Column field="prod_count_flag" header="Prod Count Flag" sortable style={{ minWidth: '1rem' }}></Column>
                        <Column field="machine_code" header="Machine Code" sortable style={{ minWidth: '1rem' }}></Column>
                        <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
                    </DataTable>

                    <Dialog visible={machineDialog} breakpoints={{ '960px': '75vw', '640px': '100vw' }} style={{ width: '40vw' }} header="Machine Details" modal className="p-fluid" footer={machineDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="machine_name">MACHINE NAME</label>
                            <InputText id="machine_name" value={machine.machine_name} onChange={(e) => onInputChange(e, 'machine_name')} required autoFocus className={classNames({ 'p-invalid': submitted && !machine.machine_name })} />
                            {submitted && !machine.machine_name && <small className="p-error">Machine Name is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="machine_description">MACHINE DESC</label>
                            <InputTextarea id="machine_description" value={machine.machine_description} onChange={(e) => onInputChange(e, 'machine_description')} required rows={3} cols={20} />
                        </div>
                        <div className="field">
                            <label htmlFor="thereotical_capacity">THEREOTICAL CAPACITY</label>
                            <InputText id="thereotical_capacity" value={machine.thereotical_capacity} onChange={(e) => onInputChange(e, 'thereotical_capacity')} required keyfilter="int"  />
                        </div>

                        <div className="field">
                            <label htmlFor="target_efficiency">TARGET EFFICIENCY</label>
                            <InputText id="target_efficiency" value={machine.target_efficiency} onChange={(e) => onInputChange(e, 'target_efficiency')} required keyfilter="int" />
                        </div>

                        <div className="field">
                            <label htmlFor="production_speed">PRODUCTION SPEED</label>
                            <InputText id="production_speed" value={machine.production_speed} onChange={(e) => onInputChange(e, 'production_speed')} required keyfilter="int"/>
                        </div>

                        <div className="field">
                            <label htmlFor="start_Date">START DATE</label>
                            <Calendar id="start_Date" value={machine.start_Date} onChange={(e) => onInputChange(e, 'start_Date')} required />
                        </div>
                        <div className="field">
                            <label htmlFor="end_Date">END DATE</label>
                            <Calendar id="end_Date" value={machine.end_Date} onChange={(e) => onInputChange(e, 'end_Date')} required />
                        </div>

                        <div className="field">
                            <label htmlFor="wait_time_sort_order">WAIT TIME SORT</label>
                            <InputText id="wait_time_sort_order" value={machine.wait_time_sort_order} onChange={(e) => onInputChange(e, 'wait_time_sort_order')} required keyfilter="int"/>
                        </div>

                        <div className="field">
                            <label htmlFor="prod_count_flag">PRODCTION COUNT FLAG</label>
                            <InputText id="prod_count_flag" value={machine.prod_count_flag} onChange={(e) => onInputChange(e, 'prod_count_flag')} required maxLength="1"/>
                        </div>

                        <div className="field">
                            <label htmlFor="machine_code">MACHINE CODE</label>
                            <InputText id="machine_code" value={machine.machine_code} onChange={(e) => onInputChange(e, 'machine_code')} required />
                        </div>
                    </Dialog>

                    <Dialog visible={deleteMachineDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteMachineDialogFooter} onHide={hideDeleteMachineDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {machine && <span>Are you sure you want to delete <b>{machine.machine_id}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteMachinesDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteMachinesDialogFooter} onHide={hideDeleteMachinesDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {machine && <span>Are you sure you want to delete the selected machine?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default CrudPage