import { useLayoutEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import '../vue/components/charting/am-charts-5/_css/amcharts5-export.css';
import '../vue/components/charting/am-charts-5/_css/amcharts5-export.min.css';
import moment from 'moment';

function EffOptimeChart(props) {
  // Define data
  let data = props.chartData;
  let formData = props.chartForm;
  let targetEfficiency = props.targetEff;

  let chartingComponents = {
    cursor: {},
    dataSets: {
      byCalendarDateTime: structuredClone(data).map((hour) => {
        return hour;
      }),
      byProductionDateTime: structuredClone(data).map((hour) => {
        return hour;
      })
    },
    export: {
      menu: {},
      plugin: {}
    },
    legend: {},
    processor: {},
    ranges: {},
    scrollBars: {
      x: {}
    },
    series: {},
    subtitles: {
      container: {},
      labels: {
        fetchedOnLabel: {},
        averageStatsLabel: {},
        timespanLabel: {}
      }
    },
    title: {},
    xAxis: {},
    xAxisTitleLabel: {},
    yAxes: {}
  }

  let dates = {
    dateTimeFormat: new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }),
    startingDateTime: new Date(formData.data.p_start_datetime),
    endingDateTime: new Date(formData.data.p_end_datetime)
  }

  function exportFilePrefix() {
    return `${formData.machineDesc}-efficiency-by-time-${dates.dateTimeFormat.format(dates.startingDateTime).replace(/,/g, '')}|${dates.dateTimeFormat.format(dates.endingDateTime).replace(/,/g, '')}`;
  }

  const chartTitles = {
    subTitles: {
      averageStats: `Avg Runtime: ${(data.reduce((val, item) => Number(val) + Number(item.on_time), 0) / data.length).toFixed(2)}min \nAvg Op Rate: ${(data.reduce((val, item) => Number(val) + Number(item.op_rate), 0) / data.length).toFixed(2)}%`,
      loadedOnDateTime: `@${dates.dateTimeFormat.format(new Date()).replace(/,/g, '')}`,
      timeSpan: `${dates.dateTimeFormat.format(dates.startingDateTime).replace(/,/g, '')} - ${dates.dateTimeFormat.format(dates.endingDateTime).replace(/,/g, '')}`,
    },
    title: `${formData.machineDesc} Efficiency`
  }

  const yAxisUnitsMaxValue = () => {
    const onTimeArray = this.dataSet.chartingComponents.hourlyMachineEfficiences.map((hourOfRuntime) => hourOfRuntime.onTime);

    return Math.max(...onTimeArray);
  }
  useLayoutEffect(() => {

    const mappedData = data.map((object) => {
      return {
        machineId:object.machine_id,
        runTime: object.measurement_time,
        onTime: object.on_time,
        operationRate: object.op_rate,
        calendarDateTime: moment(object.h_interval).format('MMM Do HH:mm')
      };
    });
    //Initialize Chart
    let root = am5.Root.new(props.id);
    root.utc = true;

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    // set Export Menu
    chartingComponents.export.menu = am5plugins_exporting.ExportingMenu.new(root, {
      align: "right",
      valign: "top"
    });

    chartingComponents.export.menu.getPrivate("menuElement").querySelector("svg").remove();
    chartingComponents.export.menu.getPrivate("menuElement").querySelector("a").innerHTML = "Export";

    chartingComponents.export.plugin = am5plugins_exporting.Exporting.new(root, {
      dataSource: mappedData,
      dataFields: {
        runTime: "Run Time (min)",
        onTime: "On Time (min)",
        operationRate: "Op Rate",
        calendarDateTime: "Hour"
      },
      filePrefix: chartTitles.title + '-efficiency-by-time-' + dates.dateTimeFormat.format(dates.startingDateTime) + '|' + dates.dateTimeFormat.format(dates.endingDateTime),
      menu: chartingComponents.export.menu,
      // disable certain formats
      csvOptions: {
        disabled: true
      },
      htmlOptions: {
        disabled: true
      },
      jpgOptions: {
        disabled: true
      },
      jsonOptions: {
        disabled: true
      },
      pdfOptions: {
        disabled: true
      },
      pdfdataOptions: {
        disabled: true
      },
      printOptions: {
        disabled: true
      }
    });

    chartingComponents.export.plugin.get("menu").get("items").shift();
    /*  In Amchart manual  
     let exporting = am5plugins_exporting.Exporting.new(root, {
         menu: am5plugins_exporting.ExportingMenu.new(root, {}),
         dataSource: data
       });
*/

    // Set Title of the chart
    chartingComponents.title = am5.Label.new(root, {
      centerX: am5.p50,
      fontSize: 20,
      fontWeight: "600",
      paddingBottom: 5,
      text: chartTitles.title,
      x: am5.p50
    });

    // set subtitle of the chart
    chartingComponents.subtitles.container = am5.Container.new(root, {
      layout: am5.GridLayout.new(root, {
        fixedWidthGrid: true,
        maxColumns: 3
      }),
      paddingBottom: -5,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: -10,
      width: am5.p100,
      x: am5.p0
    });

    chartingComponents.subtitles.labels.timespanLabel = am5.Label.new(root, {
      centerX: am5.p50,
      text: chartTitles.subTitles.timeSpan,
      x: am5.p50
    });
    chartingComponents.subtitles.labels.fetchedOnLabel = am5.Label.new(root, {
      centerX: am5.p100,
      text: chartTitles.subTitles.loadedOnDateTime,
      x: am5.p100
    });
    chartingComponents.subtitles.labels.averageStatsLabel = am5.Label.new(root, {
      centerX: am5.p0,
      text: chartTitles.subTitles.averageStats,
      x: am5.p0
    });

    chartingComponents.subtitles.container.children.push(chartingComponents.subtitles.labels.timespanLabel);
    chartingComponents.subtitles.container.children.push(chartingComponents.subtitles.labels.fetchedOnLabel);
    chartingComponents.subtitles.container.children.push(chartingComponents.subtitles.labels.averageStatsLabel);

    // Chart Creation
    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      layout: root.verticalLayout,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      panX: true,
      panY: false,
      pinchZoomX: true,
      wheelX: "panX",
      wheelY: "zoomX"
    }));

    /*  In amchart manual design
   let chart = root.container.children.push(
     am5xy.XYChart.new(root, {
       panX: false,
       panY: false,
       wheelX: "panX",
       wheelY: "zoomX",
       paddingLeft: 0,
       paddingRight:1,
       layout: root.verticalLayout
     })
   );
*/

    chart.plotContainer.get("background").setAll({
      stroke: am5.color("#343a40"),
      fill: am5.color("#343a40"),
      fillOpacity: 0.04
    });

    chart.zoomOutButton.set("forceHidden", true);

    chart.children.unshift(chartingComponents.subtitles.container)
    chart.children.unshift(chartingComponents.title);


    chartingComponents.xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "calendarDateTime",
      baseInterval: {
        count: 1,
        timeUnit: "hour"
      },
      gridIntervals: [
        { timeUnit: "hour", count: 1 },
        { timeUnit: "hour", count: 3 },
        { timeUnit: "hour", count: 6 },
        { timeUnit: "hour", count: 12 },
        { timeUnit: "day", count: 1 },
        { timeUnit: "day", count: 2 },
        { timeUnit: "day", count: 3 },
        { timeUnit: "day", count: 4 },
        { timeUnit: "day", count: 5 },
        { timeUnit: "week", count: 1 },
        { timeUnit: "month", count: 1 },
        { timeUnit: "month", count: 2 },
        { timeUnit: "month", count: 3 },
        { timeUnit: "month", count: 6 },
        { timeUnit: "year", count: 1 },
        { timeUnit: "year", count: 2 },
        { timeUnit: "year", count: 5 }
      ],
      groupData: true,
      groupIntervals: [
        { timeUnit: "hour", count: 1 },
        { timeUnit: "day", count: 1 },
        { timeUnit: "month", count: 1 }
      ],
      markUnitChange: false,
      renderer: am5xy.AxisRendererX.new(root, {
        minGridDistance: 25
      }),
      tooltip: am5.Tooltip.new(root, {}),
      tooltipLocation: 0.5
    }));

    /*   let xAxis = chart.xAxes.push(
         am5xy.CategoryAxis.new(root, {
           maxDeviation: 0,
           renderer: xRenderer,
           categoryField: "calendarDateTime"
         })
       ); */

    /*
    var xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 25,
      visible:false,
      minorGridEnabled:false
    });
*/
    chartingComponents.xAxis.get("renderer").grid.template.setAll({
      location: 0.5,
    });
    /*
    xRenderer.grid.template.setAll({
      location: 0.5
    });
    */
    chartingComponents.xAxis.get("renderer").labels.template.setAll({
      centerX: am5.p100,
      centerY: am5.p50,
      multiLocation: 0.5,
      rotation: -45
    });
    /* xRenderer.labels.template.setAll({
             centerX: am5.p100,
             centerY: am5.p50,
             multiLocation: 0.5,
             paddingBottom:47,
             rotation: -45
           }); */

    chartingComponents.xAxis.data.setAll(mappedData);



    chartingComponents.yAxes.runTime = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {
        minGridDistance: 25,
        strokeOpacity: 0.7,
        stroke: am5.color("#343a40")
      }),
      tooltip: am5.Tooltip.new(root, {})
    }));


    /*
     let yAxis = chart.yAxes.push(
          am5xy.ValueAxis.new(root, {
            renderer: yRenderer,
          })
        );

  //  above renderer
      var yRenderer = am5xy.AxisRendererY.new(root, {
        //  minGridDistance: 30, 
        //  minorGridEnabled:true
        }); 
        
        yRenderer.labels.template.setAll({
          rotation: 0,
        });
        */

    chartingComponents.yAxes.runTime.children.unshift(am5.Label.new(root, {
      fontWeight: '500',
      rotation: -90,
      text: "Runtime (min)",
      textAlign: 'center',
      y: am5.p50
    }));
    chartingComponents.yAxes.runTime.data.setAll(mappedData);


    chartingComponents.yAxes.opRate = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      max: 100,
      min: 0,
      renderer: am5xy.AxisRendererY.new(root, {
        minGridDistance: 25,
        opposite: true
      }),
      strictMinMax: true,
      tooltip: am5.Tooltip.new(root, {})
    }));
    chartingComponents.yAxes.opRate.setAll({
      numberFormat: "#'%",
      syncWithAxis: chartingComponents.yAxes.runTime
    });

    /* let yAxis1 = chart.yAxes.push(
       am5xy.ValueAxis.new(root, {
         renderer: yRenderer1
       })
     );
     var yRenderer1 = am5xy.AxisRendererY.new(root, {
       //minGridDistance: 30, 
     //  minorGridEnabled:true,
     });

      yRenderer1.labels.template.setAll({
       rotation: 0,
       visible:false
     });
     
     */

    chartingComponents.yAxes.opRate.children.push(am5.Label.new(root, {
      fontWeight: '500',
      text: "Op Rate (%)",
      textAlign: 'center',
      rotation: 90,
      y: am5.p50
    }));
    chartingComponents.yAxes.opRate.get("renderer").grid.template.set("forceHidden", true);

    chartingComponents.yAxes.opRate.data.setAll(mappedData);


    /*   let series1 = chart.series.push(
         am5xy.ColumnSeries.new(root, {
           name: "On Time(min)",
           xAxis: xAxis,
           yAxis: yAxis,
           valueYField: "runTime",
           stacked: true,
           stroke: am5.color("#343a40"),
           fill: am5.color("#2196f3"),
           categoryXField: "calendarDateTime"
         })
       );
    
       series1.data.setAll(mappedData); */




    chartingComponents.processor = am5.DataProcessor.new(root, {
      dateFields: ["calendarDateTime"],
      dateFormat: "i",
      numericFields: ["onTime", "runTime"]
    });

    //  chartingComponents.series.operationRate.setAll(mappedData);

    let series1 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        clustered: false,
        legendLabelText: "[{stroke}]{name}[/]",
        name: "On Time (Min)",
        valueYField: "runTime",
        valueYGrouped: "sum",
        xAxis: chartingComponents.xAxis,
        yAxis: chartingComponents.yAxes.runTime,
        stacked: true,
        stroke: am5.color("#343a40"),
        fill: am5.color("#2196f3"),
        categoryXField: "calendarDateTime"
      })
    );

    series1.columns.template.setAll({
      fill: am5.color("#2196f3"),
      fillOpacity: 0.3,
      strokeWidth: 1
    });

    series1.data.setAll(mappedData);

    let series2 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        clustered: false,
        fill: am5.color("#1A5276"),
        legendLabelText: "[{stroke}]{name}[/]",
        name: "Runtime (Min)",
        stroke: am5.color("#1A5276"),
        tooltip: am5.Tooltip.new(root, {
          labelText: "Minutes: [bold] {valueY}"
        }),
        layer: 0,
        valueXField: "calendarDateTime",
        valueYField: "onTime",
        valueYGrouped: "sum",
        xAxis: chartingComponents.xAxis,
        yAxis: chartingComponents.yAxes.runTime,
        categoryXField: "calendarDateTime"
      })
    );

    series2.columns.template.setAll({
      fillOpacity: 1,
      strokeWidth: 0
    });

    series2.data.setAll(mappedData);

    var series3 = chart.series.push(
      am5xy.SmoothedXLineSeries.new(root, {
        clustered: false,
        fill: am5.color("#FA2A55"),
        legendLabelText: "[{stroke}]{name}[/]",
        name: "Op Rate %",
        stroke: am5.color("#FA2A55"),
        tension: 0.7,
        tooltip: am5.Tooltip.new(root, {
          labelText: "Op Rate: [bold] {valueY}%"
        }),
        valueYField: "operationRate",
        valueYGrouped: "average",
        categoryXField: "calendarDateTime",
        xAxis: chartingComponents.xAxis,
        yAxis: chartingComponents.yAxes.opRate,
        fill: am5.color("#1A5276"),
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          labelText: "{name} in {categoryX}: {valueY} {info}"
        })
      })
    );
    series3.strokes.template.setAll({
      strokeWidth: 4
    });
    series3.data.setAll(mappedData);

    chartingComponents.ranges.targetEfficiencyRange = chartingComponents.yAxes.opRate.createAxisRange(chartingComponents.yAxes.opRate.makeDataItem({
      above: true,
      value: targetEfficiency
    }));

    chartingComponents.ranges.targetEfficiencyRange.get("label").setAll({
      background: am5.RoundedRectangle.new(root, {
        fill: am5.color("#1D8348")
      }),
      dx: -10,
      fill: am5.color("#FFFFFF"),
      centerX: am5.p100,
      inside: true,
      text: "Target Efficiency"
    });

    chartingComponents.ranges.targetEfficiencyRange.get("axisFill").setAll({
      location: 1,
      stroke: am5.color("#1D8348"),
      strokeWidth: 1,
      visible: true
    });

    chartingComponents.cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "none",
      xAxis: chartingComponents.xAxis
    }));
    chartingComponents.cursor.lineY.set("visible", false);

    chartingComponents.scrollBars.x = am5.Scrollbar.new(root, {
      orientation: "horizontal"
    });

    chart.set("scrollbarX", chartingComponents.scrollBars.x);

    let legend = chart.bottomAxesContainer.children.push(am5.Legend.new(root, {
      centerX: am5.percent(50),
      centerY: am5.percent(50),
      marginTop: -25,
      paddingBottom: 40,
      x: am5.percent(50),
      y: am5.percent(100)
    }));

    legend.data.setAll(chart.series.values);
    // chartingComponents.series.onTime.appear(1000);
    // chartingComponents.series.runTime.appear(1000);
    /// chartingComponents.series.operationRate.appear(1000);
    //chart.appear(1000, 100);

    // legend.data.setAll(chart.series.values); 
    /* let legend = chart.children.push(am5.Legend.new(root, {
       centerX: am5.p50,
       centerY: am5.p50,
       x: am5.p50,
       text: "Click and move mouse anywhere on plot area to change the graph"
     })); */

    /*  
     let exporting = am5plugins_exporting.Exporting.new(root, {
       menu: am5plugins_exporting.ExportingMenu.new(root, {}),
       dataSource: data
     }); */

    /*  chart.set("scrollbarX", am5.Scrollbar.new(root, {
        orientation: "horizontal"
      })); */

    // Create Y-axis




    return () => {
      root.dispose();
    };
  }, [data, chartingComponents]);

  return (
    <div id={props.id} style={{ width: "100%", height: "500px" }} ></div>
  )
}

export default EffOptimeChart