import { PrimeReactProvider } from 'primereact/api';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "./style/dark.scss";
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import 'primeflex/primeflex.scss';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import SideMenu from './Layouts/SideMenu';
import CrudPage from './pages/CrudPage';
import EffOpTimeChartPage from './pages/EffOpTimeChartPage';
import HomePage from './pages/HomePage';
import PecaTop from './Layouts/PecaTop';

function App() {
  return (
    <PrimeReactProvider>
       <PecaTop/>
    <Router> 
 <SideMenu/>
      <Routes>   
         
          <Route path="/" >
              <Route index  element={<HomePage/>}/>
           </Route> 
          <Route path="/crud">
             <Route index  element={<CrudPage title="machines"/>}/>
          </Route>
           <Route path="/chart">
             <Route index  element={<EffOpTimeChartPage/>}/>
          </Route>
       </Routes>
    </Router>
  </PrimeReactProvider>
  );
}

export default App;